@charset "utf-8";

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap'); */

/* CSS Document */
* {
    margin: 0px;
    padding: 0px;

}

:root {
    /*  all colors  */
    --main: #fff;
    --orange: #fd7e14;
    --dblue: #00427d;
    --black: #000;
    --lightbg: #f3f3f3;
    --darkblack: #211c16;

    /* font */
    --xsmall-font: 14px;
    --small-font: 16px;
    --medium-font: 20px;
    --xmedium-font: 28px;
    --large-font: 42px;
    --x-large-font: 50px;
    /*  box shadow    */
    --bxs-small: 0px 4px 8px 2px rgba(0, 0, 0, 0.2);
    --default-font: 'Poppins', sans-serif;
}

body {
    padding: 0;
    margin: 0;
    display: block;
    outline: none;
    font-family: var(--default-font) !important;
    font-size: 14px !important;
    font-weight: 300 !important;
}


body input,
body select,
body textarea {
    box-shadow: none !important;
    outline: none !important;
}

body button:focus {
    outline: none;
}

p {
    margin: 0 0 10px;
    color: #666;
}

ul {
    margin: 0 !important;
    list-style: none;
    padding: 0;
}

/*Top Header*/

section.top-header {
    background: var(--dblue);
}

section.top-header ul {
    text-align: right;
    margin-bottom: 0;
}

section.top-header ul li {
    display: inline-block;
    padding-left: 12px;
}

section.top-header ul li a {
    color: var(--main);
    font-size: 14px;
    display: block;
    padding: 10px;
    text-decoration: none;
}

section.top-header ul li a i {
    font-size: 18px;
    margin-right: 5px;
    position: relative;
    top: 2px;
}

/*Header*/

.header ul.navbar-nav li.nav-item a {
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 25px 0;
    display: block;
    color: #666;
    text-decoration: none;
    border-top: 3px solid transparent;
}

.header ul.navbar-nav li.nav-item a.active {
    color: var(--dblue);
    font-weight: 500;
    border-top: 3px solid var(--orange);
}

.header nav.py-3.navbar.navbar-expand-lg.auto-hiding-navbar.navbar-light {
    padding: 0px !important;
}

.header ul.navbar-nav>li.nav-item {
    display: inline-block;
    margin: 0 30px !important;
}

.header div#navbar-content {
    justify-content: center;
}

.header a.convert {
    background: var(--orange);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    color: var(--main);
    padding: 12px 20px;
    border-radius: 8px;
    text-align: center;
    letter-spacing: 1px;
    min-width: 136px;
    margin: 0 0 0 auto;
    width: 15%;
    cursor: pointer;
}

.header a.convert:hover {
    background: var(--dblue);
}

/*Banner*/

section.banner .col-md-6 {
    align-items: center;
    display: flex;
}

.banner_content p {
    font-size: 48px;
    color: var(--dblue);
    font-weight: 400;
    line-height: 1;
    margin: 0;
}

.banner_content h2 {
    font-size: 57px;
    line-height: 1;
    color: var(--orange);
    font-weight: 900;
}

section.banner {
    background: url(../images/banner-bg.jpg);
    background-size: cover;
}

/*feature*/

.comman-pt-pb {
    padding: 30px 0;
}

.title {
    font-size: 36px;
    color: var(--dblue);
    font-weight: 900;
    position: relative;
    padding: 0 0 15px;
    text-align: center;
    margin: 0 0 20px;
}

.title:before {
    width: 35px;
    height: 2px;
    background: var(--orange);
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.with_padding {
    width: 70%;
    margin: 0 auto 12px;
}

.with_padding.text-center.standout {
    font-size: 16px;
}

.sub-title {
    text-align: center;
}

.sub-title h3 {
    font-weight: 700;
    color: var(--dblue);
    font-size: 24px;
    padding: 0 0 10px;
}

.sub-title p {
    min-height: 63px;
}

.pt-20 {
    padding-top: 20px;
}

.sub-title img {
    max-width: 100%;
}

.process_img {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 10px;
    border-right: 1px solid var(--orange);
    justify-content: flex-end;
    display: flex;
}

.process_desc {
    flex: 0 0 50%;
    max-width: 50%;
    text-align: left;
    padding-left: 30px;
}

.process_content .process_desc h3 {
    font-size: 18px;
    color: var(--dblue);
    font-weight: 600;
    position: relative;
}

.process_content .process_desc h3:before {
    position: absolute;
    height: 10px;
    width: 10px;
    background: var(--orange);
    content: '';
    left: -35px;
    border-radius: 50px;
    top: 9px;
}

.process_content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.process_content:nth-child(even) .process_img {
    justify-content: flex-start;
    padding-left: 10px;
    border-left: 1px solid var(--orange);
    border-right: 0;
    padding-right: 0;
    position: relative;
    left: -1px;
}

.process_content:nth-child(even) {
    flex-direction: row-reverse;
}

.process_content:nth-child(even) .process_desc {
    padding-left: 0;
    padding-right: 30px;
    text-align: right;
}

.process_content:nth-child(even) .process_desc h3:before {
    left: auto;
    right: -35px;
}

.how-work {
    background: var(--lightbg);
}

/* Our-plan */

.plan_grid {
    margin-bottom: 30px;
}

.our_plans .plan_grid .box_shade {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .21);
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    min-height: 670px;
}

.our_plans .plan_grid h3 {
    background: var(--orange);
    color: #fff;
    font-size: 30px;
    font-weight: 700;
    padding: 10px;
    border-bottom: 3px solid var(--dblue);
    border-radius: 10px 10px 0 0;
    -moz-border-radius: 10px 10px 0 0;
    -webkit-border-radius: 10px 10px 0 0;
}

.our_plans .plan_grid h3 {
    text-align: center;
}

.our_plans .plan_grid .plan_content {
    padding: 15px;
    text-align: center;
}

.plan_grid .plan_content p.price {
    width: auto;
    text-align: center;
    font-size: 30px;
    color: #00447e;
    font-weight: 700;
    margin-bottom: 0;
}

.our_plans .plan_grid .plan_content li {
    color: #666;
    padding: 0 5px;
    margin: 8px 0;
    text-align: left;
    font-size: 14px;
    font-weight: 300;
    list-style: none;
    display: flex;
    align-items: center;
}

.our_plans .plan_grid .plan_content li i.big {
    font-size: 30px;
    font-weight: normal;
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    color: #2bc871;
}

#content article section a.button_type,
#masthead a.header_active_button,
.our_plans .plan_grid .box_shade a.button_type {
    background: var(--orange);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    color: var(--main);
    padding: 12px 20px;
    border-radius: 8px;
    text-align: center;
    letter-spacing: 1px;
    min-width: 136px;
    margin: 0 0 0 auto;
}

.our_plans .plan_grid .box_shade a.button_type {
    padding: 12px 35px;
    margin-top: 20px;
}

.our_plans .plan_grid .plan_content li.disable i {
    color: #b8b8b8;
}

.button_type {
    background: var(--orange);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    color: #fff;
    padding: 12px 20px;
    border-radius: 8px;
    text-align: center;
    letter-spacing: 1px;
}

section.footer {
    background: #343434;
    color: #999;
    font-weight: 400;
}

.widget-column.footer-widget-1 {
    float: left;
}

#colophon .widget-column h2.footer_title {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 1px;
    padding: 0 0 12px;
    position: relative;
    color: #fff;
    margin: 0 0 20px;
}

#colophon .widget-column h2.footer_title:before {
    height: 1px;
    background: #f07e07;
    width: 20px;
    position: absolute;
    bottom: 0;
    content: '';
}

ul.footer_menu {
    column-count: 3;
    width: 100%;
}

#colophon a {
    text-decoration: none;
    color: #8e8e8e;
}

#colophon a:hover {
    color: #fff;
}

.widget-column.footer-widget-1 ul.footer_menu li a {
    display: inline-block;
    font-weight: 400;
    padding: 4px 0;
}

#colophon .site_info {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
    margin-top: 25px;
}

ul.social_media li {
    display: inline-block;
    margin: 0 8px 0 0;
}

.site_info p {
    margin-bottom: 0;
}

ul.social_media li a {
    display: inline-block;
    width: 35px;
    height: 35px;
    border: 2px solid currentColor;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    font-size: 18px;
}

div#scratch-statement {
    text-align: center;
    margin-bottom: 25px;
}

.inner_page_banner {
    background: url(../images/banner-bg.jpg) left center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    text-align: left;
    padding: 58px 0;
}

.inner_page_banner .banner_content h2 {
    font-size: 36px;
    text-align: center;
    position: relative;
    color: #00447e;
    padding: 20px 0;
}

.inner_page_banner .banner_content h2:before {
    height: 3px;
    content: '';
    background: var(--orange);
    width: 45px;
    left: 0;
    right: auto;
    margin: 0 auto;
    bottom: 0;
    position: absolute;
}

.inner_page_banner .banner_content {
    display: table;
}


/* examples page */

.exaple-list {
    text-align: left;
}

.exaple-list .text_with_image {
    display: flex;
    margin: 20px 0;
    align-items: center;
}

.example_text,
.examples_image {
    padding: 25px;
}

.example_text {
    width: 60%;
}

.examples_image {
    width: 40%;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow-x: scroll;
}

.code pre {
    display: table;
    background-color: #eee;
    padding: 10px !important;
    text-align: left;
}

.text_with_image.left_image .code {
    margin-left: 10%;
}

.example_text p,
.example_text h3 {
    padding-right: 10%;
}

.left_image .example_text p,
.left_image .example_text h3,
.left_image .example_text pre {
    padding-left: 10%;
}

.exaple-list .text_with_image:nth-child(even) {
    flex-direction: row-reverse;
}

.text_with_image:nth-child(even) p,
.text_with_image:nth-child(even) h3,
.text_with_image:nth-child(even) pre {
    padding-left: 10%;
}

.example_text h3 {
    font-size: 24px;
    font-weight: bold;
    color: #02447e;
    margin: 0 0 15px;
}

.example_text p,
.example_text h3 {
    padding-right: 10%;
}

.example_text a {
    text-decoration: none;
}

/* Doc */

table.table.table-bordered tr td img {
    width: auto;
    display: table;
    margin: 0 auto;
    max-width: 100%;
}

.tbas-content-lists1 h3 {
    color: var(--dblue);
    font-size: 24px;
    font-weight: 700;
}

.docs-box {
    background: #e9f6ff;
    border-radius: 10px;
    padding: 22px;
}

.docs-box .custom-tab-bs4 {
    background: transparent;
    border: none;
}

.docs-box .custom-tab-bs4 ul li a,
.custom-tab-bs4 ul li.logout_btn span {
    border-left: 0 !important;
    text-transform: capitalize;
    padding-left: 0;
    padding-right: 0;
    font-weight: 400;
}

.docs-box .custom-tab-bs4 ul li .active,
.docs-box .custom-tab-bs4 ul li a:hover {
    border-right: navajowhite;
    border-top: none;
}

ul.emailecode {
    margin-bottom: 30px !important;
}

.plan_grid {
    margin-bottom: 30px;
}

.form_area a {
    color: var(--dblue);
}

form#my_account {
    margin-bottom: 0px;
}

.example-title {
    text-align: center;
}


/* my account */

.custom-tab-bs4 ul li {
    display: block;
}

.custom-tab-bs4 {
    background: #f5f5f5;
    border: 1px solid #dcdcdc;
    padding: 0;
    width: 100%;
    border-radius: 10px;
}

.custom-tab-bs4 ul li .active {
    border-left: 5px solid #f07e07 !important;
    background-color: transparent !important;
}

.custom-tab-bs4 ul li a,
.custom-tab-bs4 ul li.logout_btn span {
    padding: 12px 15px;
    background-position: right 15px center;
    border-left: 5px solid #ddd !important;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    border-bottom: 1px solid #d1dce5 !important;
    border-radius: 0 !important;
    color: #666;
    font-weight: 500;
}

.custom-tab-bs4 ul li .active {
    font-weight: 600;
    background: url(../images/doc_arrow‭_icon.png) 95% center no-repeat;
    color: #00447e !important;
    border-bottom: 1px solid #d1dce5;
}

.custom-tab-bs4 ul {
    display: block;
}

.custom-tab-bs4 ul li:first-child a {
    border-radius: 5px 0 0 !important;
}

.custom-tab-bs4 ul li:last-child a {
    border-radius: 0 0 0 5px !important;
    border-bottom: none !important;
}


/* ------------- */

.tbas-content-lists h3 {
    font-size: 18px;
    font-weight: 600;
    color: var(--dblue);
    margin-bottom: 20px;
}

.tbas-content-lists p {
    margin: 0 0 15px;
}

.tbas-content-lists h4 {
    font-size: 16px;
    font-weight: 500;
    padding: 0 0 10px;
    margin: 0 0 10px;
    border-bottom: 1px solid #ddd;
    position: relative;
}

.tbas-content-lists img.view_img {
    width: auto;
    border: none;
    position: absolute;
    right: 20px;
    height: auto;
    bottom: 32px;
    cursor: pointer;
}

.content-tabs .our_plans .plan_grid h3 {
    font-size: 20px;
    text-align: center;
}

.content-tabs .our_plans .plan_grid .plan_content p.price {
    font-size: 22px;
}

.content-tabs .our_plans .plan_grid .plan_content {
    padding: 10px;
}

.content-tabs .our_plans .plan_grid .plan_content li {
    font-size: 12px;
    background-size: 15px auto;
    padding: 3px 3px 3px 25px;
}

a.button_type.green {
    background: var(--green) !important;
}

.content-tabs section .our_plans a.button_type {
    background: var(--dblue) !important;
}

.content-tabs .form_area ul.four_btns {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px !important;
}

.content-tabs .form_area ul.four_btns li {
    display: inline-block;
    max-width: 25%;
    text-align: left;
    vertical-align: top;
    padding: 0 15px;
    margin: 0;
    width: 100%;
    flex: 0 0 25%;
}

.table.table.table-bordered thead {
    background: var(--dblue);
    color: #fff;
    font-size: 16px;
}

.forget-pass {
    display: flex;
    justify-content: space-between;
}

.dblock {
    text-align: center;
    width: 100%;
}

.mobile-box {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    transform: translateX(-100%);
    transition: .5s;
}

.lang-box {
    text-align: left;
    margin-bottom: 20px;
}

.custom-tab-bs4 ul li .active::after {
    /* content: "\f138"; */
    font-family: 'FontAwesome';
    font-size: 22px;
    float: right;
    position: relative;
    top: -5px;
    color: var(--orange);
}


/* ------convert box---- */

.convert_page section .wrap .form_area,
.myaccount_page section .wrap .form_area {
    width: auto;
    text-align: left;
    margin-bottom: 0;
}

.form_area ul.four_btns {
    text-align: center;
}

.form_area ul.four_btns li {
    display: inline-block;
    width: 23%;
    margin: 10px;
    text-align: left;
    vertical-align: top;
}

.form_area ul.four_btns li img {
    display: block;
    margin: 0 0 15px;
    background: #f5f5f5;
    padding: 50px;
    width: 100%;
    height: auto;
    border-radius: 10px;
    border: 1px solid #d7d7d7;
}

.form_area ul.two_btns li {
    display: inline-block;
    margin: 10px;
}

.convert_page .message_box.success {
    margin: 0 10px;
}

.message_box.success {
    border-color: #45ac07;
    color: #45ac07;
    background: #f4faeb url(../images/success-icon.png) left 15px center no-repeat;
}

.message_box.error {
    border-color: #f8a3a3;
    background: #faebeb url(../images/error-icon.png) left 15px center no-repeat;
    color: #e41a1a;
}

.message_box {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 18px 16px 18px 65px;
    text-align: left;
}

.message_box p {
    margin: 0;
}

#my_account .right_select {
    color: #666666;
    font-weight: normal;
    height: 375px;
    overflow-y: scroll;
}

#my_account .left_textarea .input_submit {
    width: auto;
    padding: 10px 40px;
}

.convert_page section #my_account .input_submit,
section .contact_form #my_account .input_submit,
.myaccount_page section #my_account .two_btns .input_submit {
    background: var(--orange);
}

.convert_page section #my_account .input_submit:hover,
section .contact_form #my_account .input_submit:hover,
.myaccount_page section #my_account .two_btns .input_submit:hover {
    background: #00447e;
}

#my_account .left_textarea textarea.input_textarea {
    height: 450px;
}

.myaccount_page #two_col_container .primary {
    background: #f5f5f5;
    border: 1px solid #dcdcdc;
    padding: 0;
    width: 27%;
}

.myaccount_page #two_col_container .secondary {
    width: 70%;
}

.myaccount_page #two_col_container .primary ul li {
    padding: 0;
}

.myaccount_page #two_col_container .primary ul li a,
.myaccount_page #two_col_container .primary ul li.logout_btn span {
    padding: 12px 15px;
    background-position: right 15px center;
    border-left: 5px solid #ddd;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
}

.myaccount_page #two_col_container .primary ul li:first-child a {
    border-radius: 5px 0 0;
}

.myaccount_page #two_col_container .primary ul li.logout_btn span {
    border-radius: 0 0 0 5px;
    border-bottom: none;
}

.myaccount_page #two_col_container .primary ul li.active a {
    border-left: 5px solid #f07e07;
}

.myaccount_page #two_col_container .primary ul li.logout_btn span {
    display: block;
    font-weight: 600;
    color: #f07e07;
    cursor: pointer;
}

.tab .form_area ul.four_btns li {
    width: 22%;
}

#two_col_container .secondary .tab .form_area ul.four_btns li img {
    border: none;
    padding: 25px;
}

.tab .our_plans .plan_grid h3 {
    font-size: 20px;
    text-align: center;
}

.tab .our_plans .plan_grid .plan_content {
    padding: 10px;
}

#content article .tab .our_plans .plan_grid .plan_content p.price {
    font-size: 22px;
}

.tab .our_plans .plan_grid .plan_content li {
    font-size: 12px;
    background-size: 15px auto;
    padding: 3px 3px 3px 25px;
}

.myaccount_page #table_format ul.heading_text li,
.myaccount_page #table_format ul li {
    width: 20%;
}

#table_format ul li:last-child {
    text-align: center;
}

#content article section .our_plans a.button_type.green {
    background: green;
}

.myaccount_page .secondary h3 {
    font-size: 18px;
    font-weight: 600;
}

#account_details ul {
    overflow: hidden;
}

#account_details ul li {
    float: left;
    width: 50%;
    padding: 10px;
    position: relative;
}

#two_col_container .secondary img.view_img {
    width: auto;
    border: none;
    position: absolute;
    right: 20px;
    height: auto;
    bottom: 32px;
    cursor: pointer;
}

#account_details ul li strong {
    font-weight: 500;
    display: block;
    margin: 0 0 10px;
}

#account_details ul li span {
    background: #f1f1f1;
    display: block;
    padding: 10px 15px;
    border-radius: 5px;
}

.myaccount_page .secondary h4 {
    font-size: 16px;
    font-weight: 500;
    padding: 0 0 10px;
    margin: 0 0 10px;
    border-bottom: 1px solid #ddd;
    position: relative;
}

.myaccount_page .secondary h4 .edit {
    position: absolute;
    right: 0;
    bottom: 5px;
    font-weight: normal;
    color: #f07e07;
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;
}

.tab #table_format ul.content_text li {
    min-height: 190px;
}

.tab #table_format ul.content_text li img {
    width: 50%;
}

.myaccount_page #two_col_container .primary ul li.logout_btn a {
    color: #f07e07;
    font-weight: 600;
}

.myaccount_page .secondary h3 a {
    color: #f07e07;
}

section #my_account label {
    font-weight: 400;
    color: #000;
}

section #my_account .left_textarea .input_text {
    background: #02447e;
    color: #fff;
    border: none;
    font-size: 14px;
}

ul.color_rgb {
    overflow: hidden;
}

ul.color_rgb li {
    float: left;
    width: 50%;
    padding: 5px;
}

/* sign up */

div#social_login ul li img {
    width: 100%;
}

div#social_login ul li {
    margin-bottom: 10px;
}

section #my_account .input_submit {
    display: block;
    border: none;
    color: #fff;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    padding: 10px 30px;
    width: 100%;
    margin-bottom: 15px;
}

section #my_account .input_submit:disabled,
section #my_account .input_submit[disabled] {
    border: 1px solid #ABB2B9;
    background: white;
    color: #ABB2B9;
    cursor: not-allowed;
}

a.btn.btn-signin-facebook {
    width: 100%;
    background: #3c5a96;
    color: #fff;
    text-align: left;
    padding-left: 60px;
    min-height: 44px;
    display: flex;
    align-items: center;
    font-size: 14px;
    position: relative;
}

a.btn.btn-signin-google {
    /* background: #4285f4; */
    width: 100%;
    text-align: left;
    /* padding-left: 60px; */
    min-height: 40px;
    align-items: center;
    display: flex;
    color: #fff;
    font-size: 14px;
    position: relative;
}

a.btn.btn-signin-facebook span {
    background-color: #fff;
    padding: 6px;
    width: 38px;
    position: absolute;
    left: 2px;
}

a.btn.btn-signin-google span {
    background-color: #fff;
    padding: 6px;
    width: 38px;
    position: absolute;
    left: 2px;
}

/* contact */

#two_col_container ul li.email {
    background: url(../images/icon_contact_email.png) left center no-repeat;
}

.emailecode li {
    padding-left: 88px;
}

#two_col_container ul li strong {
    display: block;
    color: var(--orange);
    font-size: 15px;
    font-weight: 700;
    margin: 0 0 3px;
}

#two_col_container ul li a {
    color: #333;
    border: none;
    font-size: 16px;
    padding: 0;
    display: block;
    text-decoration: none;
    font-size: 15px;
    font-weight: normal;
}

.contact_form ul {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.contact_form ul.three_col li {
    float: left;
    padding: 0 10px;
    width: 33.33%;
}

#my_account .input_text,
#my_account .input_textarea,
#my_account .input_select {
    border: 1px solid #d7d7d7;
    background: #f4f4f4;
    font-weight: 300;
    font-size: 15px;
    display: block;
    color: #666;
    font-family: 'Poppins', sans-serif;
    width: 100%;
    padding: 10px 10px;
    margin: 10px 0;
    border-radius: 8px;
    outline: none;
}

ul.boxes-2 li {
    padding: 0 10px;
    margin-bottom: 15px;
}

#my_account .input_submit {
    display: block;
    border: none;
    color: #fff;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    padding: 10px 30px;
    height: 45px;
    min-width: 140px;
    border-radius: 8px;
}

/* sign up */

div#social_login ul li img {
    width: 100%;
}

div#social_login ul li {
    margin-bottom: 10px;
}

section #my_account .input_submit {
    display: block;
    border: none;
    color: #fff;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    padding: 10px 30px;
    width: 100%;
    margin-bottom: 15px;
}

a.btn.btn-signin-facebook {
    width: 100%;
    background: #3c5a96;
    color: #fff;
    text-align: left;
    padding-left: 60px;
    min-height: 44px;
    display: flex;
    align-items: center;
    font-size: 14px;
    position: relative;
}

a.btn.btn-signin-google {
    /* background: #4285f4; */
    width: 100%;
    text-align: left;
    /* padding-left: 60px; */
    min-height: 40px;
    align-items: center;
    display: flex;
    color: #fff;
    font-size: 14px;
    position: relative;
}

a.btn.btn-signin-facebook span {
    background-color: #fff;
    padding: 6px;
    width: 38px;
    position: absolute;
    left: 2px;
}

a.btn.btn-signin-google span {
    background-color: #fff;
    padding: 6px;
    width: 38px;
    position: absolute;
    left: 2px;
}

section #my_account .input_submit {
    display: block;
    border: none;
    color: #fff;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: Poppins, sans-serif;
    cursor: pointer;
    padding: 10px 30px;
    width: 100%;
    margin-bottom: 15px;
}

#my_account .input_submit,
.input_submit,
.two_btns .input_submit {
    background: var(--orange);
}

#my_account .input_submit:hover,
.input_submit:hover,
.two_btns .input_submit:hover {
    background: var(--dblue);
}

.btn-google {
    max-height: 60px;
}

section.reset p {
    text-align: center;
    width: 100%;
    margin-bottom: 30px;
}

/* cms-page faq*/

.legal h1 {
    font-weight: bold;
    color: #00447e;
    font-size: 24px;
    padding: 0 0 10px;
}

.legal h2.cms-title {
    font-size: 24px;
    margin-top: 30px;
}

.legal h2 {
    font-weight: bold;
    color: #00447e;
    font-size: 18px;
    padding: 0 0 10px;
}

.legal h3 {
    font-weight: bold;
    color: darkgray;
    font-size: 18px;
    padding: 0 0 10px;
}

.legal ul {
    padding-bottom: 10px;
}

.legal a {
    font-weight: bold;
}

.our_plans .plan_grid .box_shade a.button_type.disabled {
    background-color: lightgray;
}

.our_plans .plan_grid h3 {
    text-align: center;
}

.even {
    background-color: #EBF5FB;
}

.doc_image {
    width: 40%;
}

.doc_markdown {
    width: 30%;
}

.doc_desc {
    width: 30%;
}

#scratch-statement {
    text-align: center;
}

#beta-text {
    background: black;
    color: yellow;
    padding-left: 15px;
}

/*my account*/

button.close:hover {
    opacity: 1 !important;
}


.modal-dialog.modal-regsiters .modal-content {
    border-radius: 0;
}

h4.mv0.pb3.f5.fw6.bb.bw2.b--gray1.word-wrap {
    font-size: 18px;
    text-transform: capitalize;
    color: var(--dblue);
    font-weight: 600;
}

.modal-dialog.modal-regsiters {
    transform: translate(-50%, -50%) !important;
    top: 50%;
    position: absolute;
    left: 50%;
    max-width: 500px;
    width: 100%;
}


.planrate span h1 {
    display: inline-block;
    vertical-align: middle;
    padding-right: 10px;
    color: var(--dblue);
    font-weight: 600;
    font-size: 48px;
}

.planrate {
    font-size: 20px;
}


ul.nav.nav-tabs.horizontab {
    display: flex;
    justify-content: normal;
}

.nav.nav-tabs.horizontab li a img {
    width: 25px;
    margin-right: 15px;
}

ul.nav.nav-tabs.horizontab li {
    font-size: 18px;
    font-weight: 600;
}

ul.nav.nav-tabs.horizontab li a svg {
    height: 22px;
    margin-right: 10px;
}

.horizontab .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    color: #c1c1c1;
}

.horizontab .nav-item.show .nav-link,
.horizontab .nav-link.active {
    color: var(--orange) !important;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
    border-top: none;
    border-left: navajowhite;
    border-right: none;
    border-bottom: 2px solid var(--dblue) !important;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: #e9ecef #e9ecef #dee2e6;
    border: transparent;
}

ul.nav.nav-tabs.horizontab li {
    padding-right: 50px;
}

.subscribe.btn.btn-primary.btn-block {
    min-height: 60px;
    font-size: 24px;
    background-color: var(--dblue);
    border-bottom: 5px solid #00396b !important;
    border-color: var(--dblue);
    margin-top: 30px;
}

.data-user h4 {
    font-size: 17px;
    color: #666;
}

.data-user h4 {
    border: #ffdead;
    padding-bottom: 0;
}

.data-user h5 {
    font-size: 17px;
    color: #666;
    margin-top: 15px;
    display: table;
}

.change-infos a {
    color: #00427d;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none !important;
}

.change-infos {
    text-align: right;
}

form.addedit {
    text-align: left;
}

/* Convert */

.left_textarea .input_submit {
    display: inline-block !important;
    margin-right: 10px;
}

.options {
    background-color: #efeeee;
    padding: 10px 10px;
    border-radius: 5px;
}

#my_account .right_select div.tab-content {
    padding-top: 15px;
}

.options .custom-control-label {
    position: relative;
    padding-top: 2px;
}

.desktop-hide {
    display: none;
}


body .swiper-button-next,
body .swiper-container-rtl .swiper-button-prev {
    right: 10px;
    left: auto;
    color: var(--orange);
}

body .swiper-button-prev,
body .swiper-container-rtl .swiper-button-next {
    left: 0px;
    right: auto;
    color: var(--orange);
}

body .swiper-button-next:after,
body .swiper-container-rtl .swiper-button-prev:after {
    font-size: 30px;
}

body .swiper-button-prev:after,
body .swiper-container-rtl .swiper-button-next:after {
    font-size: 30px;
}

body .swiper-container-horizontal>.swiper-pagination-bullets,
body .swiper-pagination-custom,
.swiper-pagination-fraction {
    bottom: 0px;
    left: 0;
    width: 100%;
}

body .swiper-pagination-bullet-active {
    opacity: 1;
    background: var(--orange);
}

body.manu_open_body {
    overflow: hidden;
}

.dashboard-mobile-filter {
    display: none;
}

.legal h2.cms-title {
    font-size: 18px;
    margin-top: 30px;
}

.legal a {
    font-weight: bold;
    word-break: break-all;
}

.inner_page_banner .banner_content {
    display: table;
    /* margin: 0px auto; */
}

.inner_page_banner .banner_content h2:before {
    left: 0;
    right: 0;
    margin: 0 auto;
}

@media screen and (min-width: 768px) {
    .user-ic {
        display: none !important;
    }
}

@media only screen and (min-width:1200px) {
    .container {
        max-width: 1170px;
    }
}

@media screen and (min-width:768px) {
    .filter-tabs {
        display: none !important;
    }
}

@media only screen and (max-width:991px) {
    section.header {
        padding: 10px 15px;
    }

    span.navbar-toggler-icon::before {
        content: "\f0c9";
        font-family: 'FontAwesome';
        font-size: 25px;
        color: #fff;
        height: auto;
        line-height: 33px;
    }

    .navbar-light .navbar-toggler-icon {
        background-image: none;
    }

    button.navbar-toggler.collapsed {
        order: 3;
        margin-left: 10px;
        background: #00427d !important;
    }

    button.navbar-toggler {
        order: 3;
        margin-left: 10px;
        background: #00427d !important;
    }

    .navbar-light .navbar-toggler-icon {
        background-image: none;
    }

    .header div#navbar-content {
        display: block !important;
        position: fixed;
        top: 0;
        background: #00427d;
        z-index: 99;
        left: -100%;
        height: 100%;
        width: 240px;
        color: #fff;
        transition: all 0.2s ease;
    }

    .header div#navbar-content.show {
        left: 0;
    }

    .header ul.navbar-nav li.nav-item a.active {
        color: #fff;
        font-weight: 500;
        border-top: 3px solid var(--orange);
        background: var(--orange);
    }

    .header ul.navbar-nav li.nav-item a {
        font-size: 15px;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding: 10px 15px;
        display: block;
        color: #fff;
        text-decoration: none;
        border-top: none !important;
    }

    .header ul.navbar-nav>li.nav-item {
        display: inline-block;
        margin: 0 0px !important;
        border-bottom: 1px solid #0255a0;
    }

    .banner_content p {
        font-size: 28px;
    }

    .banner_content h2 {
        font-size: 46px;
    }

    button.navbar-toggler.manu_open span::after {
        content: '';
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.7);
        z-index: 9;
        width: 100%;
        height: 100%;
    }

    .mobile-hide {
        display: none;
    }

    .desktop-hide {
        display: block;
    }

    .feature-coll {
        padding: 30px 0px;
        text-align: center;
    }

    .feature-coll img {
        margin: 0px auto;
        display: block;
        width: auto !important;
        max-width: 100% !important;
    }

    div.owl-nav>button span {
        font-size: 70px;
        line-height: 30px;
        display: inline-block;
        vertical-align: top;
        color: var(--orange);
    }

    div.owl-nav>button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -10px;
    }

    div.owl-nav>button.owl-next {
        right: -10px;
        left: initial;
    }

    .owl-dots {
        display: none;
    }

}

@media only screen and (max-width:767px) {
    section.header a.navbar-brand img {
        max-width: 100px;
    }

    .swiper-slide {
        padding: 0px 20px;
    }

    pre {
        font-size: 100.5%;
    }

    .desktop-hide .plan_grid {
        padding: 17px;
    }

    section.banner {
        padding: 25px 0px;
        text-align: center;
    }

    .banner_content h2,
    #content section h2.title {
        font-size: 28px;
    }

    .banner_content p {
        font-size: 24px;
    }

    .title {
        font-size: 28px;
    }

    .header a.convert {
        padding: 12px 10px;
        min-width: 105px;
    }

    .with_padding {
        width: 100%;
    }

    .process_img {
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 10px;
        border-right: none;
        justify-content: flex-end;
        display: flex;
    }

    .process_desc {
        flex: 0 0 100%;
        max-width: 100%;
        text-align: left;
        padding-left: 0px;
    }

    .process_content {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
    }

    .process_img {
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 0px;
        border-right: none;
        justify-content: flex-end;
        display: flex;
    }

    .process_content .process_desc h3:before {
        display: none;
    }

    .process_desc {
        text-align: center;
    }

    .process_content:nth-child(even) .process_img {
        border-left: none;
    }

    .process_content:nth-child(even) .process_img {
        padding-left: 0px;
        border-left: none;
        border-right: 0;
        padding-right: 0;
    }

    .process_content:nth-child(even) .process_desc {
        padding-left: 0;
        padding-right: 0;
        text-align: center;
    }

    .process_content:nth-child(even) .process_desc h3:before {
        display: none;
    }

    .process_img img {
        max-width: 100%;
    }

    .our_plans .plan_grid h3 {
        font-size: 24px;
    }

    ul.footer_menu {
        column-count: 2;
        width: 100%;
    }

    #colophon .widget-column h2.footer_title {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 300;
        letter-spacing: 1px;
        padding: 0 0 12px;
        position: relative;
        color: #fff;
        margin: 25px 0 15px;
    }

    #colophon .site_info {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 12px;
        margin-top: 25px;
        padding: 0px 15px;
    }

    div#scratch-statement {
        text-align: center;
        margin-bottom: 0px;
    }

    .feature-coll {
        padding: 30px 0px 0px;
        text-align: center;
    }

    .inner_page_banner .banner_content h2 {
        font-size: 28px;
    }

    .inner_page_banner {
        padding: 25px 0;
    }


    .exaple-list .text_with_image {
        display: block;
    }

    .example_text {
        width: 100%;
    }

    .example_text,
    .examples_image {
        padding: 10px;
    }

    .examples_image {
        width: 100%;
    }

    .text_with_image.left_image .code {
        margin-left: 0px;
    }

    .left_image .example_text p,
    .left_image .example_text h3,
    .left_image .example_text pre {
        padding-left: 0px;
    }

    .example_text p,
    .example_text h3 {
        padding-right: 10%;
    }

    .example_text {
        width: 100%;
        text-align: center;
    }

    .code pre {
        margin: 0px auto;
    }

    .lang-box label {
        display: block;
    }

    .lang-box {
        display: inline-block;
    }

    a.filter-tabs svg {
        width: 30px;
        height: 30px;
        fill: #00427d;
    }

    a.filter-tabs {
        display: inline-block;
        float: right;
        margin-top: 25px;
    }

    .mobile-box.open_sidebar {
        transform: translateX(0%);
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
    }

    .docs-box {
        border-radius: 0px;
    }

    .left_textarea {
        padding: 10px 10px;
        box-sizing: border-box;
    }

    #my_account .input_submit {
        min-width: 138px;
    }

    #my_account .left_textarea .input_submit {
        width: auto;
        padding: 10px 10px;
    }

    a.user-ic {
        display: inline-block;
        float: right;
    }

    a.user-ic svg {
        width: 30px;
        height: 30px;
        fill: #00447e;
    }

    .inner_page_banner .banner_content h2:before {
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .dashboard-mobile-filter {
        position: fixed;
        background-color: #fff;
        z-index: 111;
        bottom: 0;
        width: 100%;
        box-shadow: 0 0 5px 2px #d1d1d1;
        padding-top: 15px;
        transform: translateY(100%);
        transition: .5s;
    }

    .account_open_sidebar.dashboard-mobile-filter {
        transform: translateY(0%);
    }

    ul.dashboard-menu li a {
        color: #00427d;
        font-size: 14px;
        font-weight: 600;
    }

    .close-dashboard {
        color: #000;
        font-size: 26px;
        font-weight: 500;
        position: absolute;
        right: 15px;
        top: -5px;
    }

    h3 {
        font-size: 22px;
    }

    data-user h4 {
        font-size: 14px;
    }

    .dashboard-mobile-filter {
        display: block;
    }
}

.cookie-bar {
    position: fixed;
    width: 100%;
    right: 0;
    bottom: 0;
    height: auto;
    max-width: 800px;
    background: black;
    padding: 5px;
    z-index: 99;
    line-height: 25px;
    text-align: center;
}

.cookie-bar-text {
    color: yellow;
    padding: 10px 20px;
    font-size: 1.1em;
}

.cookie-bar-close {
    width: 16px;
    height: 16px;
    background: url("../images/close_white.png");
    float: right;
    background-repeat: no-repeat;
    background-size: 20px 20px;
}

.cookie-bar-close a.cc-cookie-accept {
    display: inline-block;
    width: 16px;
    height: 16px;
}

.cc-cookie-buttons .cc-cookie-accept {
    background-color: #4CAF50;
    /* Green */
    border-radius: 5px;
    border: none;
    color: white !important;
    padding: 10px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px;
}

/* override the colour options for drawingheader */
/* #drawing-header.nav-tabs .nav-item.show .nav-link,
#drawing-header.nav-tabs .nav-link.active {
    color: #fff;
    background-color: #007bff;
    border-color: #dee2e6 #dee2e6 #fff;
} */

.info_box {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 18px 16px 18px 65px;
    text-align: left;
}

.convert-section div.info_box {
    border-color: #fd7e14;
    color: #fd7e14;
    background: #fceee2;
    padding-left: 16px;
}

.convert-section div.form_area {
    margin-top: 10px;
}

.convert-section div.info_box {
    border-color: #777;
    color: #777;
    background: #fceee2;
    padding-left: 16px;
}

.convert-section div.info_box a {
    color: #fd7e14;
}

.convert-section .nav-tabs .nav-link {
    border: none;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    background: #ccc;
    /* border-radius: 0px; */
    color: #fff;
}

.convert-section .nav-tabs .nav-link:hover {
    background: #aaa;
}

.convert-section .nav-tabs a.nav-link.active {
    background: #fd7e14 !important;
    color: #fff !important;
    border: none !important;
}

.convert-section .tab-content #editor,
.convert-section .tab-content #options {
    padding-top: 15px;
}

.convert-section .tab-content #options {
    padding-top: 15px;
    /* padding-left: 15px;
    padding-right: 15px; */
}

.convert-section div.form_area h5 {
    color: #666;
    margin-bottom: 10px;
}

.convert-section .editor textarea {
    width: 100%;
    min-height: 415px;
    padding: 15px;
}

.editor {
    height: 355px;
}

.editor.errors-expanded {
    height: 255px;
}

.details {
    height: 0;
}

.details.errors-expanded {
    height: 100px;
}

.convert-section .statistics {
    display: inline-block;
    width: 100%;
}

.convert-section .incrementor-container.incrementor {
    display: inline-block;
    float: right;
    border: 1px solid #fd7e14;
    border-radius: 5px;
    margin-bottom: 20px;
    position: absolute;
    bottom: 100px;
    right: 40px;
    background-color: #fd7e14;
    height: 30px;
}

.convert-section .incrementor-container.incrementor span {
    display: inline-block;
    width: 20px;
    text-align: center;
    border-left: 1px solid #fd7e14;
    height: 28px;
    vertical-align: top;
    border-right: 1px solid #fd7e14;
    width: 38px;
    line-height: 28px;
    font-size: 18px;
    box-shadow: none;
    background-color: white;
}

.convert-section .incrementor-container.incrementor button.btn {
    box-shadow: none !important;
    outline: none !important;
}

.convert-section ul.nav-tabs {
    min-height: 40px;
    height: 40px;
}

.drawing {
    height: 465px;
    padding-bottom: 70px;
}

.svg-container {
    height: 100%;
}

.svg {
    height: 100%;
    overflow-y: scroll;
}

.statistics {
    margin-bottom: -70px;
    height: 70px;
    display: block;
    padding: 10px;
}

@media screen and (min-width: 768px) {
    .drawing {
        padding-bottom: 40px;
    }

    .statistics {
        margin-bottom: -40px;
        height: 40px;
    }
}

.show-errors .details {
    overflow-y: scroll;
}

.show-errors .header,
.show-errors .details {
    padding: 0 5px;
}

.error-line .error-line-header {
    font-size: 1em;
    font-weight: 700;
}

.error-line .error-line-no {
    font-size: 1em;
    font-weight: 700;
    width: 30px;
    text-align: right;
    padding-right: 5px;
}

.error-icon {
    cursor: pointer;
}

.footer .site-footer .social_media i {
    line-height: 32px;
}

/* Works on Firefox */
.container * {
    scrollbar-width: thin;
    scrollbar-color: #d6dee1 transparent;
}

/* Works on Chrome, Edge, and Safari */
.container *::-webkit-scrollbar {
    width: 20px;
}

.container *::-webkit-scrollbar-track {
    background-color: transparent;
}

.container *::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

.container *::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}

.form-control.invalid {
    border-color: red;
}

.help {
    font-size: 11px;
}

#options {
    height: 450px;
    overflow-y: scroll;
    overflow-x: hidden;
}

/* The following is used with the Codemirror Editor placeholder text */
.CodeMirror-empty {
    color: #D5D8DC !important;
}

.credit-translation {
	font-weight: 700;
}

.credit-translation img {
	width: 200px;
}

#messages:last-child {
    margin-bottom: -30px;
}